.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
  background-color: rgba(53, 53, 53, 0.8);
  border: 1px solid rgba(110, 94, 132, 0.35);

  &[data-selected] {
    background-color: var(--primary);
    border: var(--primary);
  }

  &[data-selected] .indicator {
    opacity: 1;
  }

  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }
}

.small {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.medium {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.indicator {
  color: var(--text-primary);
  opacity: 0;
}
