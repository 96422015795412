.item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  background-color: transparent;
}

.item[data-active='true'] {
  background-color: #ebebeb;
}

.item[data-active='true'] .icon {
  opacity: 1;
}

.icon {
  color: #7b8492;
  opacity: 0;
}
