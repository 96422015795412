.container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
}

.title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 4px;
  color: var(--text-white-ui);
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-white-ui);
  margin-bottom: 16px;
  text-align: center;
}

.link {
  height: 48px;
  width: 100%;
  display: flex;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  color: #fff;

  &:hover {
    background: var(--primary-purple-dark);
  }

  &:focus-visible {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }
}
