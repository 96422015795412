.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.divider {
  width: 100%;
  height: 1px;
  background: #1d1e23;
}
