.container {
  padding: 0 16px 16px 16px;
}

.button {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: transparent;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  &[data-hovered] {
    background-color: var(--primary);
    color: #fff;
  }

  &[data-disabled] {
    background-color: rgb(247, 247, 247);
    border-color: rgb(247, 247, 247);
    color: rgb(160, 174, 192);
    cursor: not-allowed;
  }
}

.primary {
  background-color: var(--primary);
  color: #fff;

  &[data-hovered] {
    background-color: var(--primary-purple-dark);
  }
}
