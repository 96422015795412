.dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #131416;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  padding: 16px;

  @media (min-width: 768px) {
    max-width: 487px;
    width: 100%;
    height: auto;
    border-radius: 16px;
    min-width: 0px;
    min-height: 0px;
    padding: 32px;
  }

  & * {
    box-sizing: border-box;
  }
}

.title {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.22px;
  margin-bottom: 6px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  gap: 8px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.button {
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    min-width: 148px;
  }
}
