.container {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 40px;
  padding-inline: 8px;
  color: #fff;
  font-family: 'Normal';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;

  &:hover,
  &:focus,
  &[data-active='true'] {
    background-color: var(--ui-level1-new);
  }
}

.icon {
  color: #a0aec0;
}

.image {
  height: 16px;
  width: 16px;
  border-radius: 2px;
}

.spacer {
  flex: 1;
}
