.container {
  flex-shrink: 0;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
}

.highlighted {
  border-width: 2px;
  border-color: var(--primary);
  background-color: var(--white);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}

.header {
  padding: 24px 16px;
  border-bottom: 1px solid #d6d6d6;
}

.planName {
  color: var(--text-white-ui);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 4px;
}

.priceContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}

.price {
  color: var(--text-white-ui);
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.priceBillingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--text-inactive);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.planDetails {
  flex: 1;
  padding: 16px;
  /* clear white shadow */
  padding-bottom: 24px;
  overflow-y: auto;
}

.topWhiteShadow {
  box-shadow: 0 -24px 24px 0 #fff;
}

.terms {
  padding-bottom: 12px;
  padding-inline: 24px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-white-ui);
  /* balance text centered */
  text-wrap: balance;
}

.termsLink {
  color: var(--primary);
  text-decoration: underline;
}
