.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 600px;
  max-height: 100%;
  max-width: 100%;
}

.text {
  font-size: 20px;
  color: #fff;
}
