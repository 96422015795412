.container {
  width: fit-content;
  padding: 6px 8px;

  background-color: #26282c;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);

  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  text-wrap: balance;
}

.overlayArrow {
  fill: #26282c;
  pointer-events: none;
}
