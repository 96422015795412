:root {
  --black: #000000;
  --black-rgb: 0, 0, 0;
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --white-level1: #f7f7f7;

  /* keeping these two with new blue for safety */
  --primary-purple: #2c22fa;
  --primary-purple-dark: #2c22fa;

  --primary-purple-light: #d6b6ff;
  --primary-purple-light-rgb: 214, 182, 255;

  --primary: #2c22fa;

  --brand-optional-green: #61eed5;
  --brand-optional-green-rgb: 97, 238, 213;

  --danger: #ff552f;
  --danger-dark: #d9381c;

  --ui-background: #000000;
  --ui-level0: #121316;
  --ui-level0-alpha: rgba(244, 245, 246, 0.12);
  --ui-level0-rgb: 18, 19, 22;
  --ui-level0-layerbase: #000000;
  --ui-level1: #1d1e23;
  --ui-level1-new: #25262b;
  --ui-level1-layerbase: #131416;
  --ui-level1-rgb: 29, 30, 35;
  --ui-level1-uiColor: #2f3237;
  --ui-level1-alpha: rgba(244, 245, 246, 0.08);
  --ui-level2: #32343a;
  --ui-level2-rgb: 65, 69, 80;
  --ui-level2-layerbase: #26282c;
  --ui-level2-new: #383c42;
  --ui-level3: #575b6b;
  --ui-hoverable: #a5b4c7;
  --ui-hoverable-rgb: 165, 180, 199;
  --ui-inactive: #15161a;
  --ui-overlay: rgba(53, 53, 53, 0.8);

  --interactive-primary: #2c22fa;
  --interactive-primary-hover: #0c04b4;
  --interactive-secondary: #29292d;
  --interactive-secondary-rgb: 41, 41, 45;
  --interactive-secondary-hover: #dae0e7;
  --interactive-tertiary: #b3affd;
  --interactive-tertiary-rgb: 179, 175, 253;
  --interactive-tertiary-hover: #8d88fc;
  --recipes-container-stroke: rgba(110, 94, 132, 0.35);

  --ui-border0: #1d1e23;
  --ui-border1: #29292d;
  --ui-border2: #3e3e43;

  --ui-border-level1: #29292d;

  --light-ui-level2: #d6d6d6;
  --light-ui-inactive: #f7f7f7;

  --text-primary: #ffffff;
  --text-primary-rgb: 255, 255, 255;
  --text-secondary: #a0aec0;
  --text-white-ui: #2a2c3a;
  --text-inactive: #7c839c;

  --gray-500: #3b3f46;
  --gray-600: #30313a;
  --gray-800-old: #1d1e23;
  --gray-800-new: #121315;

  --new-ui-border-level0: #2b2b30;

  --modal-zoom-animation: modal-zoom 300ms cubic-bezier(0.165, 0.84, 0.44, 1)
    forwards;

  --lightbox-sidebar-width: 362px;

  --Level0-UIColor: #26282c;
  --Level0-Alpha: rgba(244, 245, 246, 0.12);

  --help-widget-z-index: 14;
}

@keyframes modal-zoom {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}
