.container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  color: var(--ui-hoverable);

  position: absolute;
  top: 16px;
  right: 16px;

  &[data-hovered] {
    opacity: 0.5;
  }

  @media (min-width: 1200px) {
    position: static;
    top: 0px;
    right: 0xp;
  }
}
