.container {
  flex-wrap: wrap;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-inline: 16px;
}

.link {
  position: relative;
  color: var(--ui-hoverable);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;

  &:hover {
    color: var(--primary);
  }
}

.icon {
  position: absolute;
  top: 0px;
}
