.container {
  padding: 0 16px 16px 16px;
}

.button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--gray-600);
  background: var(--white);
  color: var(--text-white-ui);
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  &[data-hovered] {
    background-color: rgba(var(--white-rgb), 0.9);
  }

  &[data-disabled] {
    background-color: var(--gray-600);
    border: 1px solid var(--gray-600);
    color: var(--text-inactive);
    cursor: not-allowed;
  }
}

.primary {
  background-color: var(--primary);
  color: #fff;

  &[data-hovered] {
    background-color: var(--primary-purple-dark);
  }
}

.enterprisePrice {
  color: var(--text-inactive);
}