.trigger {
  padding: 0px;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  cursor: pointer;
  background: var(--Level0-UIColor);
  border: 1px solid #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  position: relative;
  margin-right: 0 !important; /* override default margin-right */

  &[data-focus-visible] {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }
}

.popover {
  box-sizing: border-box;
  width: 430px;
  max-width: 430px;
  background: var(--Level0-UIColor);
  box-shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
  border-radius: 8px;
  overflow: auto;

  &[data-entering] {
    animation: popover-slide 200ms;
  }

  &[data-exiting] {
    animation: popover-slide 200ms reverse ease-in;
  }
}

@keyframes popover-slide {
  from {
    transform: translateY(-8px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.popup {
  position: absolute;
  width: 336px;
  max-width: 336px;
  top: 50px;
  right: 31px;
  background: var(--Level0-UIColor);
  box-shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
  z-index: 100;
  border-radius: 8px;
  cursor: pointer;
}

.popup .item {
  border-bottom: none;
}

.content {
  display: flex;
  flex-direction: column;
}

.itemContainer {
  position: relative;
  padding: 0 20px;
}

.itemContainer:first-child .item {
  flex-direction: column;
}

.itemContainer:first-child img {
  aspect-ratio: 389 / 214;
  width: 100%;
  height: auto;
  background-color: #1c1c1c;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid var(--ui-border2);
  padding: 16px 0;
}

.itemContent {
  display: flex;
  gap: 12px;
}

.itemContainerLast .item {
  border-bottom: none;
}

.item video {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 4px;
}

.item img {
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
  border-radius: 6px;
  background-color: #1c1c1c;
  flex-shrink: 0;
}

.itemHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.date {
  color: var(--text-secondary);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  gap: 4px;
  display: flex;
  align-items: center;
}

a.itemContainer:hover {
  background-color: var(--ui-level2);
}

a.itemContainer:hover .itemHeaderRight,
a.itemContainer:hover .itemHeaderRight span {
  color: #fff;
}

.item p {
  color: var(--text-secondary);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.footerLink {
  border-top: 1px solid var(--ui-border2);
  color: var(--text-secondary);
  padding: 16px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.footerLink a {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footerLink a:hover {
  color: #fff;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ui-level0);
  z-index: 100;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.closeIcon {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  align-self: flex-end;
  padding: 4px;
}

.unreadIndicator {
  width: 4px;
  height: 4px;
  background-color: var(--interactive-tertiary);
  border-radius: 50%;
  border: 2px solid var(--ui-border1);
  position: absolute;
  top: 5px;
  right: 4px;
}

.loading,
.loadFailed {
  padding: 20px;
  text-align: center;
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 475px;
  gap: 12px;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadFailed p {
  max-width: 250px;
}

.loadFailed a {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
}

.loadFailedLinkIcon {
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
}

.loadFailedIcon {
  color: var(--text-inactive);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ui-level2);
  border-radius: 6px;
}
