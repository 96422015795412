.input {
  max-width: 392px;
  width: 100%;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid transparent;
  color: #a0aec0;
  font-size: 14px;
  background-color: #1d1e23;
  font-weight: 500;
}

.input[data-active='true'],
.input:focus {
  background-color: rgba(255, 255, 255, 0.8);
  color: #2a2c3a;
}

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 43%,
      #000 45%,
      #000 55%,
      rgba(0, 0, 0, 0) 57%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 43%,
      #000 45%,
      #000 55%,
      transparent 57%,
      transparent 100%
    );
}

.dropdown {
  box-sizing: border-box;
  position: fixed;
  background-color: #fff;
  opacity: 1;
  padding-top: 16px;
  z-index: 50;
  overflow: hidden;
  overflow: auto;
  top: var(--top);
  left: 0px;
  right: 0px;
}

.dropdown::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #fff;
}

.dropdown::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #32343a;
}

@media (min-width: 768px) {
  .dropdown {
    border-radius: 4px;
    left: var(--left);
    width: var(--width);
    right: initial;
  }
}
