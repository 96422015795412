.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-inline: 24px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--ui-level1);
}

.title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 32px;
}

.closeButton {
  width: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0px;
  color: var(--ui-hoverable);
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-hovered] {
    opacity: 0.6;
  }

  &[data-focus-visible] {
    outline: 1px solid #fff;
  }
}
