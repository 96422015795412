.container {
  flex-shrink: 0;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.iconContainer {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.12);
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 24px;
}
