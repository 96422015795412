.input {
  box-sizing: border-box;
  height: 24px;
  max-width: 320px;
  border: none;
  font-size: 14px;
  background: #000;
  color: #fff;

  &[data-state='error'] {
    border-color: var(--danger-dark);
  }

  &::placeholder {
    color: var(--text-inactive);
  }
}
