.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 2px;
  height: 40px;
  border-radius: 20px;
  background: #f1f1f4;
}

.item {
  cursor: pointer;
  color: #2a2c3a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 36px;
  padding: 0px 16px;
  border-radius: 18px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
}

.itemActive {
  color: #fff;
  background: #15161a;
}
