.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 8px;
}

.folderContainer {
  height: 42px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 2px;
  flex-shrink: 0;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.nameText {
  font-size: 14px;
  line-height: 20px;
  color: #2a2c3a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detailsText {
  font-size: 14px;
  line-height: 20px;
  color: #7b8492;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
