.container {
  height: 32px;
  display: flex;
  align-items: center;
  padding-inline: 8px;
  text-transform: uppercase;
  color: #7c839c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 10px;
}
