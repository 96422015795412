.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  width: 100%;
  color: var(--text-primary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.icon {
  position: relative;
  top: 2px;
  margin-left: -4px;
  color: var(--text-inactive);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fragments {
  flex: 1;
  padding-right: 12px;
}

.infoIcon {
  flex-shrink: 0;
  color: #a0aec0;
  background-color: transparent;
  padding: 0;
  border: none;

  /* match text line-height */
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.strong {
  font-weight: 600;
  letter-spacing: 0.01em;
}

.indentedList {
  margin-top: -8px;
}

.indentedItem {
  color: var(--text-inactive);
  gap: 0; 
}