.container {
  flex-shrink: 0;
  color: #7c839c;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
}

.link {
  color: var(--text-primary);
}
