.dialog {
  width: 90vw;
  max-width: 448px;
  max-height: 615px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  z-index: 2000;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 32px;
}

.description {
  color: var(--text-secondary);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.divider {
  height: 1px;
  background: var(--ui-level1);
}

.checkboxContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
