.trigger {
  padding: 0px;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  background: var(--gray-600);
  border: 1px solid #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-focus-visible] {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }
}

.triggerImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.triggerInitials {
  color: #dfdfe4;
  font-size: 12px;
  line-height: 16px;
}

.popover {
  box-sizing: border-box;
  width: 240px;
  background: #1d1e23;
  box-shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
  padding: 8px 12px;
  border-radius: 4px;
  overflow-x: auto;

  &[data-entering] {
    animation: popover-slide 200ms;
  }

  &[data-exiting] {
    animation: popover-slide 200ms reverse ease-in;
  }
}

@keyframes popover-slide {
  from {
    transform: translateY(-8px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.profileSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.profilePicture {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  overflow: hidden;
  background: #131315;
  border: 1px solid #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.profilePictureImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profilePictureInitials {
  font-size: 12px;
  line-height: 16px;
  color: #dfdfe4;
}

.profileName {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}

.profileEmail {
  font-size: 12px;
  line-height: 16px;
  color: rgb(160, 174, 192);
}

.separator {
  border: none;
  height: 1px;
  width: 100%;
  background-color: rgb(65, 69, 80);
  margin-top: 8px;
  margin-bottom: 8px;
}

.menuItem {
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 4px;
  width: 100%;
  background-color: transparent;
  border: none;

  &[data-focused] {
    background: #32343a;
  }
}

.menuItemTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuItemTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuItemSubtitle {
  font-size: 12px;
  line-height: 16px;
  color: #a0aec0;
}

.upgradeItem {
  margin-top: 8px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 4px;
  width: 100%;
  background-color: transparent;
  border: none;
  background: var(--primary);
  font-size: 12px;
  line-height: 16px;

  &[data-focused] {
    background: var(--primary);
  }
}

.footer {
  display: block;
  font-size: 10px;
  line-height: 16px;
  color: #a0aec0;
  text-align: center;
}
