.container {
  flex-shrink: 0;
  height: 100%;

  border-radius: 8px;
  border: 1px solid var(--ui-level1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  background: var(--ui-level1);
  color: var(--text-primary);

  @media (min-width: 1200px) {
    border-radius: 0;
    border: 0;
  }
}

.header {
  padding: 16px;

  @media (min-width: 1200px) {
    padding: 24px 16px;
  }
}

.planName {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 4px;
  font-size: 17px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;

  @media (min-width: 1200px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 16px;
  }
}

.planDescription {
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}

.recommendedTag {
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  padding: 6px 4px;
  width: 114px;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.priceContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: var(--text-primary);
  white-space: pre;
  @media (min-width: 1200px) {
    margin-bottom: 16px;
  }
}

.price {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.28px;

  @media (min-width: 1200px) {
    font-size: 40px;
    letter-spacing: 0.01em;
  }
}

.priceBillingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 20px;
  font-weight: 350;
  line-height: 28px;
}


.planDetails {
  flex: 1;
  padding: 16px;
  background-color: var(--ui-inactive);

  @media (min-width: 1200px) {
    min-height: 300px;
    overflow: auto;
    background-color: transparent;
  }
}

.terms {
  margin-top: 40px;
  padding-bottom: 12px;
  padding-inline: 24px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
  /* balance text centered */
  text-wrap: balance;
}

.termsLink {
  color: var(--text-primary);
  text-decoration: underline;
}
