.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.8);
  backdrop-filter: blur(10px);
  z-index: 100000;

  display: flex;
  justify-content: center;
  align-items: center;

  &[data-entering] {
    animation: modal-fade 200ms;
  }

  &[data-exiting] {
    animation: modal-fade 150ms reverse ease-in;
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  max-width: 90vw;
  max-height: 90vh;
  box-sizing: border-box;
  background-color: #000;
  border-radius: 6px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &[data-entering] {
    animation: var(--modal-zoom-animation);
  }
}
