.dialog {
  display: flex;
  flex-direction: column;
  background-color: var(--ui-level1-layerbase);
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  @media (min-width: 768px) {
    max-width: 934px;
    width: 100%;
    height: 700px;
    border-radius: 16px;
    min-width: 0px;
    min-height: 0px;
    max-height: 90vh;
  }

  & * {
    box-sizing: border-box;
  }
}

.top {
  width: 100%;
  flex: 1;
  min-height: 0;
  position: relative;
  margin-bottom: -80px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -80px;
  background: linear-gradient(
    180deg,
    rgba(19, 20, 22, 0) 0%,
    rgba(19, 20, 22, 0) 30%,
    var(--ui-level1-layerbase) 75%
  );
}

.closeButton {
  all: unset;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 16px;
  right: 16px;
  border-radius: 8px;
  background-color: #2f3237;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a0aec0;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

.bottom {
  position: relative;
  width: 100%;
  padding-inline: 24px;
  padding-bottom: 24px;
  flex-shrink: 0;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -80px;
  z-index: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.title {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.36px;
}

.subtitle {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.legal {
  color: var(--text-inactive);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 32px;
  margin-bottom: 8px;
  max-width: 80%;

  & a {
    color: var(--text-secondary);
    text-decoration-line: underline;
  }
}

.button {
  width: 129px;
  margin: 16px 0;
}
