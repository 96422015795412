.button {
  border: 0;
  outline: 0;
  background: var(--primary-purple);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  position: fixed;
  bottom: 24px;
  right: 32px;
  color: var(--ui-background);
  cursor: pointer;
  z-index: var(--help-widget-z-index);
}
