.outer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100vw;
  height: 100dvh;
  background-color: var(--ui-inactive);
  color: #ffffff;
  overflow: auto;

  & * {
    box-sizing: border-box;
  }

  @media (min-width: 1200px) {
    background-color: var(--ui-level1);
  }
}

.container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  position: relative;
  flex-shrink: 0;

  &[data-has-banner='true'] {
    padding-top: 20px;
  }

  @media (min-width: 1200px) {
    flex: 1;
    min-height: 0px;
  }
}

.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-inline: 40px;
  margin-bottom: 12px;

  @media (min-width: 1200px) {
    justify-content: space-between;
  }
}

.headerLeft {
  width: 32px;
  height: 32px;
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
}

.title {
  text-align: center;
  font-size: 28px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.28px;

  @media (min-width: 1200px) {
    font-size: 36px;
    font-weight: 450;
    letter-spacing: -0.36px;
    line-height: 40px;
  }
}

.subtitle {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 12px;
}

.subtitleProduct {
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
