.container {
  flex-shrink: 0;
  width: 100%;
  padding-block: 16px;
  padding-inline: 16px;
  box-sizing: border-box;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (min-width: 768px) {
    padding-inline: 32px;
  }
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 24px;
}

.button {
  flex-shrink: 0;
  height: 36px;
  padding-block: 0px;
  padding-inline: 16px;
  color: var(--primary);
  background-color: #fff;
  border-radius: 18px;
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 20px;
  cursor: pointer;

  &[data-hovered],
  &[data-pressed] {
    opacity: 0.7;
  }

  &[data-focus-visible] {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }
}
