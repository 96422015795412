.row {
  display: flex;
  align-items: center;
  gap: 8px;

  padding-block: 16px;
  padding-inline: 8px;
  border-radius: 8px;
  border: 1px solid var(--ui-level1);
}

.avatar {
  margin-right: 12px;
}

.details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  min-width: 0px;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.role {
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 20px;
}

.decline {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border: none;
  background-color: var(--danger);
  color: #fff;
  cursor: pointer;

  &[data-hovered] {
    background-color: var(--danger-dark);
  }

  &[data-focus-visible] {
    outline: 2px solid var(--white);
    outline-offset: 2px;
  }
}

.accept {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border: none;
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;

  &[data-hovered] {
    background-color: var(--primary-purple-dark);
  }

  &[data-focus-visible] {
    outline: 2px solid var(--white);
    outline-offset: 2px;
  }
}
