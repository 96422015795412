.container {
  flex: 1;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (min-width: 1200px) {
    overflow: auto;
  }
}

.innerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  gap: 16px;

  @media (min-width: 1200px) {
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.padding {
  display: none;
  flex-shrink: 0;
  width: 40px;
  height: 100%;

  @media (min-width: 1200px) {
    display: block;
  }
}

.item {
  width: 100%;
  flex-shrink: 0;

  @media (min-width: 1200px) {
    height: 100%;
    width: var(--item-width);
  }
}
