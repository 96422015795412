.container {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.block {
  border-radius: 2px;
  background-color: #d6d6d6;
  animation: loading 1.5s infinite ease-in-out;
  background-color: #fff;
}

@keyframes loading {
  0% {
    background-color: #ebebeb;
  }
  50% {
    background-color: #d3d6da;
  }
  100% {
    background-color: #ebebeb;
  }
}
