.dialog {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 368px;
  text-align: center;
  border-radius: 20px;
}

.headerContainer {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.heading {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  margin: 0 46px;
}

.secondaryText {
  font-size: 16px;
  line-height: 24px;
  color: var(--text-secondary);
  margin-bottom: 8px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkboxText {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-secondary);
}

.closeButton {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0px;
  color: var(--ui-hoverable);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  position: absolute;
  right: 0;
  top: 0;

  &[data-hovered] {
    opacity: 0.6;
  }

  &[data-focus-visible] {
    outline: 1px solid #fff;
  }
}
