.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 4px 8px 4px 12px;
  border-radius: 96px;
  background: var(--ui-level1);
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
}

.removeButton {
  color: var(--text-secondary);
  background-color: transparent;
  border: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &[data-focus-visible],
  &[data-hovered] {
    color: #fff;
  }
}
