.runningTasks {
  background: var(--ui-hoverable);
  color: #2a2c3a;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
