.container {
  width: 440px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-right: 12px;
  overflow: hidden;
}

.container,
.container * {
  box-sizing: border-box;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 32px;
  margin-bottom: 8px;
  padding-right: 12px;
}

.description {
  color: var(--text-secondary);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 24px;
  padding-right: 12px;
}

.email {
  color: #fff;
}

.teams {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 12px;
  gap: 8px;
  flex: 1;
}
