.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 1px;
}

.more {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 40px;
  padding-inline: 8px;
  background: transparent;
  width: 100%;
  border: none;
  color: #7c839c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  cursor: pointer;

  &[data-hovered],
  &[data-pressed],
  &[data-focus-visible] {
    background: #282b2f;
    color: #fff;
  }
}
