.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
}

.fullWidth {
  width: 100%;
}

.shadow {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
}

.primaryBlue {
  background: var(--interactive-primary);
  color: #fff;

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    background: var(--interactive-primary-hover);
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    background: #1d1e23;
    color: #a0aec0;
    cursor: not-allowed;
  }
}

.primaryWhite {
  background: #fff;
  color: #000;

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    background: #dae0e7;
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    background: #fff;
    color: var(--text-inactive);
    cursor: not-allowed;
  }
}

.secondary {
  background: #1d1e23;
  color: #fff;

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    opacity: 0.8;
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    background: #1d1e23;
    color: #a0aec0;
    cursor: not-allowed;
    opacity: 1;
  }
}

.tertiary {
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid #2f3237;

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    opacity: 0.8;
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    background: transparent;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.outline {
  background-color: transparent;
  border: 1px solid #32343a;
  color: #fff;

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    background-color: rgba(244, 245, 246, 0.08);
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    background: #1d1e23;
    color: #a0aec0;
    cursor: not-allowed;
    opacity: 1;
  }
}

.destructive {
  background: var(--danger);
  color: #fff;

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    background: var(--danger-dark);
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: 2px;
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    background: #1d1e23;
    color: #a0aec0;
    cursor: not-allowed;
  }
}

.ghost {
  background-color: transparent;
  color: var(--text-secondary);

  a&:hover,
  &[data-hovered],
  &[data-pressed] {
    color: var(--text-primary);
  }

  a&:focus-visible,
  &[data-focus-visible] {
    outline: 1px solid #fff;
    outline-offset: -1px;
    color: var(--text-primary);
  }

  a&:disabled,
  &[data-disabled],
  &[data-soft-disabled='true'] {
    color: #a0aec0;
    cursor: not-allowed;
  }
}

.small {
  gap: 4px;
  height: 28px;
  padding-inline: 8px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.medium {
  height: 32px;
  padding-inline: 12px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.large {
  height: 40px;
  padding-inline: 12px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.iconSmall {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
  padding-inline: 0;
}

.iconMedium {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.iconLarge {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
}
