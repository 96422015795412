.container {
  padding: 8px 16px;
  border-top: 1px solid #f1f1f4;
  display: flex;
  gap: 24px;
  height: 32px;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: #7b8492;
  font-size: 12px;
  line-height: 16px;
}
