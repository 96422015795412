.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  overflow-y: hidden;
  border: solid 1px var(--ui-level2);
  border-radius: 8px;
  background-color: #000;

  &:focus-within {
    border-color: var(--primary);
  }

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid white;
    border-radius: 9999px;
    background-color: var(--ui-hoverable);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.leftColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 132px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid var(--ui-level2);
    border-radius: 9999px;
    background-color: var(--ui-level2);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
