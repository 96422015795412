.container {
  --height: 24px;

  height: var(--height);
  display: flex;
  align-items: center;

  position: absolute;
  top: calc(-1 * var(--height) / 2);
  left: 50%;
  transform: translateX(-50%);

  background-color: var(--primary);
  border-radius: var(--height);

  color: var(--text-primary);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  padding-inline: 12px;
  white-space: nowrap;
}
