.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.8);
  backdrop-filter: blur(10px);
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;

  &[data-entering] {
    animation: modal-fade 200ms;
  }

  &[data-exiting] {
    animation: modal-fade 150ms reverse ease-in;
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  max-width: 100dvw;
  max-height: 100dvh;
  box-sizing: border-box;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: min(1200px, 90vw);
    max-width: min(1200px, 90vw);
    height: min(700px, 90vh);
    max-height: min(700px, 90vh);
    border-radius: 16px;
  }

  &[data-entering] {
    animation: var(--modal-zoom-animation);
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--ui-level1-layerbase);
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 767px) {
    overflow-y: auto;
    justify-content: center;
  }

  @media (min-width: 768px) {
    border-radius: 16px;
    min-width: 0px;
    min-height: 0px;
  }

  & * {
    box-sizing: border-box;
  }
}

.container {
  display: flex;
  flex-direction: row;
  padding: 48px;
  gap: 48px;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 24px;
    overflow-y: auto;
    max-height: 100dvh;
  }
}

.left {
  width: calc(50% - 24px);
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.footer {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-secondary);
  text-align: center;
}

.right {
  position: relative;
  width: calc(50% - 24px);
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 24px;
  }
}

.title {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.22px;
}

.subtitle {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.videoPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
}

.workflowSubtitle {
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  min-height: 48px;
  display: flex;
  align-items: flex-start;
}

.previewVideo {
  position: relative;
  z-index: 1;
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  max-height: 525px;
  margin-top: 68px;
}

.workflowContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.workflow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  min-width: 100%;
  border: 1px solid var(--ui-level1-alpha);
  border-radius: 8px;
  padding: 12px 16px 12px 12px;
  cursor: pointer;

  &[data-active='true'],
  &:hover {
    background-color: var(--ui-level1-alpha);
    border-color: transparent;
  }
}

.workflowName {
  flex: 1;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.workflowDescription {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.workflowImage {
  width: 64px;
  height: 64px;
  border-radius: 4px;
  object-fit: cover;
}

.workflowButton,
.upgradeButton {
  all: unset;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  background-color: var(--ui-level1-uiColor);
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upgradeButton {
  padding: 4px 6px;
}

.closeButton {
  all: unset;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 24px;
  right: 24px;
  border-radius: 8px;
  background-color: var(--ui-level1-uiColor);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a0aec0;
  cursor: pointer;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

.blurredBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(50px);
  z-index: 0;
}
