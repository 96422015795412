.trigger {
  height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 0px;
  cursor: pointer;
  color: var(--text-secondary);

  &[data-focus-visible] {
    outline: 1px solid var(--primary);
  }
}

.popover {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--ui-level1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--ui-level1);
  border-radius: 2px;
  min-width: 125px;
}

.item {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;

  &[data-focused],
  &[data-hovered] {
    background-color: var(--primary);
  }
}
