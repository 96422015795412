.creditsText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
}

.infoIcon {
  flex-shrink: 0;
  color: var(--text-secondary);
  background-color: transparent;
  padding: 0;
  border: none;
  height: 12px;
  width: 12px;
}