.dialog {
  position: relative;
  width: 600px;
  max-width: 100%;
}

.content {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionHeader {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #fff;
}

.inviteViaEmailSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline: 16px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--ui-level1);
}

.errorMessage {
  font-size: 14px;
  line-height: 20px;
  color: var(--danger);
  text-align: center;
  flex-shrink: 0;
}
