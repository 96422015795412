.container {
  border: none;
  width: 100%;
  cursor: pointer;
  background: #f97583;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1c1c;
  font-size: 14px;
  position: relative;
  z-index: 3;
  flex-shrink: 0;
}
