.dialog {
  position: relative;
  max-width: 500px;
}

.content {
  padding-inline: 16px;
  padding-bottom: 12px;
}

.contactSupport {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 120px;
}

.inviteViaEmailForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
