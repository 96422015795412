.dialog {
  width: 600px;
  width: 500px;
  max-width: 100%;
  padding-bottom: 24px;
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #fff;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline: 24px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
