.container {
  color: inherit;
  cursor: pointer;
  text-align: unset;
  border-radius: 8px;
  padding: 16px 8px;
  gap: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #282b2f;
  background-color: transparent;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #282b2f;
  }

  &[data-selected='true'] {
    background-color: #282b2f;
    border-color: var(--ui-hoverable);
  }
}

.title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.planName {
  color: #a0aec0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
