.container {
  background-color: var(--ui-level0);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  gap: 6px;
  cursor: pointer;

  &[data-hovered],
  &[data-focus-visible] {
    background: #282b2f;
    color: var(--text-primary);
  }
}
