.container {
  height: 64px;
  border-radius: 8px;
  padding-inline: 8px;
  gap: 12px;
  display: flex;
  align-items: center;
  border: 1px solid var(--ui-level2);
  background-color: transparent;
  flex-shrink: 0;

  &:hover,
  &:focus {
    background-color: #282b2f;
  }
}

.title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.plan {
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.check {
  color: var(--ui-hoverable);
}
