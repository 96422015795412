.header {
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: #7b8492;
  padding: 0px 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #f1f1f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header svg {
  width: 14px;
  height: 14px;
}
