.container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
}

.title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 4px;
  color: var(--text-white-ui);
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-white-ui);
  text-align: center;
}
