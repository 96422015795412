.container {
  padding: 0 48px;
}

.title {
  max-width: 230px;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.36px;
  line-height: 40px;
  margin-bottom: 50px;
}

.table {
  table-layout: fixed;
  border-spacing: 32px 0;

  @media (min-width: 1200px) {
    width: 100%;
  }
}

.tableHead {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--white);
}

.table td {
  overflow: hidden;
  height: 64px;
}

.featureRow {
  border-bottom: 1px solid var(--gray-600);
}

.featureCell {
  vertical-align: middle;
  text-align: center;
  padding: 0 16px;
}

.titleCell {
  vertical-align: middle;
}

.expandFeature {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;

  text-transform: uppercase;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.14px;
  padding: 18px 0;
}

.toggleIcon {
  position: relative;
  margin-left: -4px;
  color: var(--text-inactive);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  color: var(--text-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  padding: 16px;

  @media (min-width: 1200px) {
    padding: 24px 16px;
  }
}

.planName {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 4px;
  font-size: 17px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;

  @media (min-width: 1200px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 16px;
  }
}

.recommendedTag {
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  padding: 6px 4px;
  width: 114px;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.priceContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: var(--text-primary);
  white-space: pre;
  @media (min-width: 1200px) {
    margin-bottom: 16px;
  }
}

.price {
  font-size: 28px;
  font-weight: 450;
  line-height: 36px;
  letter-spacing: -0.28px;

  @media (min-width: 1200px) {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0.01em;
  }
}

.priceBillingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 20px;
  font-weight: 350;
  line-height: 28px;
}
