.container {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  align-items: center;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
