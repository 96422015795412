.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  background-color: #000;
}

.container,
.container * {
  box-sizing: border-box;
}

.menu {
  flex-shrink: 0;
  padding: 12px 16px 0px 16px;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 0;
}

.beta {
  color: var(--text-secondary);
  font-size: 12px;
  padding: 2px 8px;
  background-color: var(--ui-level1);
  border-radius: 4px;
}

.scroller {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.padded {
  flex: 1;
  width: 100%;
  padding: 0px 16px 16px 16px;
}

.numberOfTools {
  color: #7c839c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.spacer {
  flex: 1;
}

.footer {
  border-top: 1px solid #282b2f;
  padding: 8px 16px;
}

.helpLink {
  display: flex;
  gap: 6px;
  height: 36px;
  align-items: center;
  color: #fff;
  font-family: 'Normal';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.terms {
  height: 36px;
  display: flex;
  align-items: center;
  color: #a0aec0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  & a {
    color: #fff;
  }
}
