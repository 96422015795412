.dialog {
  position: relative;
  max-width: 500px;
}

.content {
  padding: 24px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 12px;
}

.description {
  color: #fff;
  margin-bottom: 24px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
