.container {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
  height: 42px;
  width: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 2px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
