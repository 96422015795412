.container {
  height: 32px;
  display: flex;
  align-items: center;
}

.divider {
  height: 1px;
  width: 100%;
  background: #1d1e23;
}
