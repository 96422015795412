.menuTrigger {
  background-color: var(--ui-level0);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px;
  cursor: pointer;

  &[data-rounded-bottom] {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &[data-hovered],
  &[data-focus-visible] {
    background-color: var(--ui-level2);
    color: var(--text-primary);
  }

  &[data-pressed] {
    background-color: var(--ui-level2);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
    color: var(--text-primary);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.teamName {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.userName {
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.menuIcon {
  color: var(--ui-hoverable);
}

.popover {
  background-color: var(--ui-level2);
  width: var(--trigger-width);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
}

.divider {
  width: calc(100% - 8px);
  height: 1px;
  margin-inline: auto;
  background-color: var(--ui-level1-alpha);
}

.menuItem {
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-inline: 16px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &[data-focused] {
    background-color: rgba(255, 255, 255, 0.08);
  }

  &:last-child[data-focused] {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: #282b2f;
}
