.container {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.header {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
  text-transform: uppercase;
  color: #7c839c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 10px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
}

button.header {
  cursor: pointer;

  &[data-hovered],
  &[data-pressed],
  &[data-focus-visible] {
    background-color: #282b2f;
    color: #fff;
  }
}
