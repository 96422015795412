.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 8px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.nameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 8px;
}

.nameText {
  font-size: 14px;
  line-height: 20px;
  color: #2a2c3a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detailsText {
  font-size: 12px;
  line-height: 16px;
  color: #7b8492;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
