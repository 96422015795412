.container {
  cursor: pointer;
  height: 64px;
  border-radius: 8px;
  padding-inline: 16px;
  gap: 12px;
  display: flex;
  align-items: center;
  border: 1px solid var(--ui-level2);
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  flex-shrink: 0;

  &:hover,
  &:focus {
    background-color: #282b2f;
  }
}
