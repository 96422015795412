.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  background-color: #000;
  overflow-y: auto;
  padding: 12px 16px;
}

.menu {
  width: 100%;
  height: 97px;
  background-color: #2b2c33;
  border-radius: 8px;
  animation: pulse 2s infinite;
}

.sectionList {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.item {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-inline: 8px;
}

.itemLeft {
  width: 16px;
  height: 16px;
  background-color: #2b2c33;
  border-radius: 4px;
  animation: pulse 2s infinite;
}

.itemRight {
  height: 16px;
  flex: 1;
  background-color: #2b2c33;
  border-radius: 4px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
