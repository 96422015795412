.container {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 14px;
  height: 40px;
  padding-left: 16px;
  padding-right: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;

  &:hover,
  &:focus,
  &[data-active='true'] {
    background: #282b2f;
  }
}

.indicator {
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.item {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 40px;
  padding-left: 24px;
  padding-right: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;

  &:hover,
  &:focus {
    background: #282b2f;
  }
}

.icon {
  color: #a0aec0;
}
