.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.link {
  color: var(--ui-hoverable);
  text-decoration: underline;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.checkboxIndicator {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--ui-level2);
  background: var(--ui-level1);
  color: #fff;

  &[data-selected='true'] {
    background-color: var(--primary);
    border: var(--primary);
  }

  &[data-focus-visible] {
    outline: 1px solid var(--primary);
  }
}
