.container {
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #29292d;
  border: 1px solid #29292d;
  padding: 8px;
  gap: 8px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  margin-inline: 16px;
}

.topRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.dollarIcon {
  background-color: rgba(214, 182, 255, 0.2);
  color: var(--ui-hoverable);
  width: 16px;
  height: 16px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}

.message {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}

.link {
  color: var(--ui-hoverable);
  text-decoration: underline;
}
