.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 40px;
  border-radius: 20px;
  background: var(--ui-background);
}

.item {
  cursor: pointer;
  color: var(--text-inactive);
  
  border-radius: 18px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;

  height: 32px;
  font-size: 15px;
  line-height: 20px;
  padding: 0px 12px;

  @media (min-width: 1200px) {
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 36px;
  }
}

.itemActive {
  color: var(--text-white-ui);
  background: var(--white);
}
